import ScanButton from 'components/navigations/app-navigation/scan-button/ScanButton';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import arrow from 'assets/Images/arrow-white.png';
import './walletVoucherDetailsProfit.scss';
import { FormattedMessage } from 'react-intl';

interface WalletVoucherDetailsProfitProps {
    shouldShow: boolean;
    onClose: () => void;
}

const WalletVoucherDetailsProfit = ({ shouldShow, onClose }: WalletVoucherDetailsProfitProps) => (
    <DraggablePopup
        className="wallet-profit"
        show={shouldShow}
        onClose={onClose}
    >
        <h4>
            <FormattedMessage
                id="wallet-voucher-details.online.use-in-store.title"
                values={{
                    span: (msg) => <span>{msg}</span>,
                }}
            />
        </h4>

        <div className="wallet-profit-scanner">
            <div className="wallet-profit-highlight">
                <img src={arrow} alt="arrow"/>
                <h5>Cliquez ICI</h5>
            </div>
            <ScanButton
                className="wallet-profit-scan"
                style={{
                    transform: 'unset',
                }}
            />
        </div>
    </DraggablePopup>
);

export default WalletVoucherDetailsProfit;
