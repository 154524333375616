import { useEffect, useState } from 'react';

import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';
import { createFormData } from 'services/form';
import { sleep } from 'services/promise';

const handleCatch = (error) => {
    console.error(error?.message ? error.message : error);
};
const initialStatus = {
    isSubmitted:  false,
    isValidating: false,
    isApproved:   'PENDING',
    website: ''
};

export default function useWalletCouponOnline(fetchStatusOnMount = { orderId: null }) {
    const [isLoading, setIsLoading] = useState(false);
    const [validationStatus, setValidationStatus] = useState(initialStatus);

    const requestValidation = async (data) => {
        setIsLoading(true);

        try {
            const formData = createFormData(data);

            const response = await TaooServerClient.post(endpoints.onlineCoupon.requestValidation, formData);

            let result = null;

            if (response.status === 200) {
                result = response.data;
            }

            return result;
        } catch (error) {
            handleCatch(error);

            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const useOnline = async (id) => {
        setIsLoading(true);

        try {
            const response = await TaooServerClient.post(endpoints.onlineCoupon.offerUseOnline, {
                id,
            });

            let result = null;

            if (response.status === 200) {
                result = response.data;
            }

            return result;
        } catch (error) {
            handleCatch(error);

            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const getValidationStatus = async (orderId) => {
        setIsLoading(true);

        try {
            const response = await TaooServerClient.get(endpoints.onlineCoupon.getRequestStatus(orderId), {
                orderId,
            });

            let result = null;

            if (response.status === 200) {
                result = response.data;
            }

            const payload = {
                isSubmitted:  result?.submitted ?? initialStatus.isSubmitted,
                isValidating: result?.validating ?? initialStatus.isValidating,
                isApproved:   result?.status ?? initialStatus.isApproved,
                website: result?.website ?? initialStatus.website
            };

            setValidationStatus(payload);

            return payload;
        } catch (error) {
            handleCatch(error);

            return null;
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!fetchStatusOnMount.orderId) {
            return;
        }

        getValidationStatus(fetchStatusOnMount.orderId);
    }, [fetchStatusOnMount?.orderId]);

    return {
        useOnline,
        requestValidation,
        refetchValidationStatus: getValidationStatus,
        validationStatus,
        isLoading,
    };
}
