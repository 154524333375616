import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import 'moment/locale/fr';
import {
    Fragment, useCallback, useEffect, useRef, useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import NoResult from 'components/no-result/index';
import { endpoints } from 'constants/api';
import { LoaderInButtonFillColor } from 'constants/defaults';
import TaooServerClient from 'http/axios';
import { durationAsDaysToSeconds } from 'services/date';
import { copyTextToClipboard } from 'services/navigator';
import { QueryParams } from 'services/proxy';
import checkSrc from 'services/utils/checkSrcImage';
import replacePathParams from 'services/utils/replacePathParams';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import useLocale from 'hooks/locale/useLocale';

import styles from './index.module.scss';
import WalletVoucherDetailsProfit from './profit/WalletVoucherDetailsProfit';
import WalletVoucherDetailsUseCode from './use-code/WalletVoucherDetailsUseCode';
import WalletVoucherDetailsOnlineValidation from './validation/WalletVoucherDetailsOnlineValidation';

function WalletVoucherDetails() {
    const params = useParams();
    const intl = useIntl();
    const lang = useLocale();
    const navigate = useNavigate();

    const [voucher, setVoucher] = useState(null);
    const [shouldRerender, setShouldRerender] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [codeCopied, setCodeCopied] = useState(false);

    const codeRef = useRef();

    useEffect(() => {
        const queryParams = new QueryParams();
        const isExpired = queryParams.getParam('isExpired');

        isExpired && setIsExpired(isExpired === 'true');
    }, []);

    useEffect(() => {
        if (isLoading || voucher) {
            return;
        }

        setIsLoading(true);

        TaooServerClient.get(endpoints.wallet.getWalletItem(params.id))
                .then((res) => {
                    const modifiedVoucher = { ...res.data };

                    modifiedVoucher.cover = checkSrc(modifiedVoucher.cover);
                    setVoucher(modifiedVoucher);
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => setIsLoading(false));
    }, [shouldRerender]);

    const goToPointsHistory = () => {
        navigate(All_PATHS_NAMESPACES[lang].points_history.path);
    }

    const discountCode = voucher?.code ?? null;
    const copyCode = useCallback(() => {
        copyTextToClipboard(voucher?.code);

        setCodeCopied(true);

        setTimeout(() => {
            setCodeCopied(false);
        }, 1000);
    }, [voucher]);

    const expiredAt = voucher && voucher?.expireAt ? durationAsDaysToSeconds(new Date(), voucher.expireAt) : null;

    const [showProfitPopup, setShowProfitPopup] = useState(false);
    const [showUseCodePopup, setShowUseCodePopup] = useState(false);
    const [showValidationPopup, setShowValidationPopup] = useState(false);

    const [isStoryLoading, setIsStoryLoading] = useState(false);
    const goToStory = async () => {
        setIsStoryLoading(true);
        try {
            const response = await TaooServerClient.get(endpoints.story.getStory(2));
            
            if(!response || !response?.data) {
                throw new Error('API Error')
            }

            const storageKey = 'cached-story';
            const story = response.data;

            localStorage.setItem(storageKey, JSON.stringify(story));

            const route = replacePathParams(
                All_PATHS_NAMESPACES[lang].story.path,
                {},
                ':',
                {
                    key: storageKey,
                },
            );

            navigate(route);
        } catch(e) {
            console.error(e)
        } finally {
            setIsStoryLoading(false)
        }
    }

    const hasOnlineCode = voucher && voucher?.code;
    const canUseOnline = voucher?.canUseOnline;
    const canUseOffline = voucher?.canUseOffline;

    return (
        <>
            <PageTransitionWrapper className={`${ styles.container } mb-5`}>
                <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.wallet-offer' })} />

                {/* eslint-disable-next-line */}
                {/* {(!voucher || !voucher.uuid || voucher.errors) && !isLoading ? ( */}
                {voucher && voucher?.uuid ? (
                    <>
                        <h5 className="mb-3 text-center w-100">
                            {voucher.type === 'VOUCHER' 
                                ? <FormattedMessage id="wallet-voucher-details.offer-type.voucher" />
                                : <FormattedMessage id="wallet-voucher-details.offer-type.discount" />}
                        </h5>
                        <div className="d-flex justify-content-center col-gap-10">
                            {voucher.companies.map((el, i) => (
                                <Fragment key={i}>
                                    {checkSrc(el.logo) && (
                                        <Link
                                            to={replacePathParams(All_PATHS_NAMESPACES[lang].store_single.path, {
                                                store: el.id,
                                            })}
                                            className={styles['company-img']}
                                        >
                                            <Img src={checkSrc(el.logo)} alt="" />
                                        </Link>
                                    )}
                                </Fragment>
                            ))}
                        </div>
                        <div className="d-flex align-items-center justify-content-between col-gap-10">
                            <h4 className="mb-0">{voucher.title}</h4>
                            <div className={styles.discount}>
                                {/* eslint-disable-next-line */}
                                {voucher.type === 'VOUCHER' ? (
                                    <h6 className="mb-0">{voucher.moneyAmount}DT</h6>
                                ) : voucher.discountType === 'PERCENT' ? (
                                    <h6 className="mb-0">{voucher.discountPercentAmount}%</h6>
                                ) : (
                                    <h6 className="mb-0">{voucher.discountMoneyAmount}DT</h6>
                                )}
                            </div>
                        </div>
                        <div className={styles['voucher-description']}>
                            <div className={`${ styles.leftArrow_top } ${ styles['svg-wrapper'] }`}>
                                <svg viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.194 11.6856C19.6488 12.8856 19.6488 15.1144 18.194 16.3144L4.90886 27.2718C2.95214 28.8856 -1.50794e-06 27.4938 -1.53819e-06 24.9574L-1.79952e-06 3.04261C-1.82976e-06 0.506207 2.95213 -0.88563 4.90885 0.728246L18.194 11.6856Z"
                                        fill="#0E1118"
                                    />
                                </svg>
                            </div>
                            <div className={`${ styles.leftArrow_bottom } ${ styles['svg-wrapper'] }`}>
                                <svg viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.194 11.6856C19.6488 12.8856 19.6488 15.1144 18.194 16.3144L4.90886 27.2718C2.95214 28.8856 -1.50794e-06 27.4938 -1.53819e-06 24.9574L-1.79952e-06 3.04261C-1.82976e-06 0.506207 2.95213 -0.88563 4.90885 0.728246L18.194 11.6856Z"
                                        fill="#0E1118"
                                    />
                                </svg>
                            </div>
                            <div className={`${ styles.rightArrow_top } ${ styles['svg-wrapper'] }`}>
                                <svg viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.80601 16.3144C0.351154 15.1144 0.351154 12.8856 1.80601 11.6856L15.0911 0.728244C17.0479 -0.885632 20 0.506202 20 3.04261L20 24.9574C20 27.4938 17.0479 28.8856 15.0911 27.2718L1.80601 16.3144Z"
                                        fill="#0E1118"
                                    />
                                </svg>
                            </div>
                            <div className={`${ styles.rightArrow_bottom } ${ styles['svg-wrapper'] }`}>
                                <svg viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.80601 16.3144C0.351154 15.1144 0.351154 12.8856 1.80601 11.6856L15.0911 0.728244C17.0479 -0.885632 20 0.506202 20 3.04261L20 24.9574C20 27.4938 17.0479 28.8856 15.0911 27.2718L1.80601 16.3144Z"
                                        fill="#0E1118"
                                    />
                                </svg>
                            </div>
                            <div
                                className={`${ styles['voucher-description-item'] } d-flex align-items-center justify-content-between`}
                            >
                                <p>
                                    <FormattedMessage id="wallet-voucher-details.voucher-id" />
                                </p>
                                <p className="bold">{voucher.uuid}</p>
                            </div>
                            <div
                                className={`${ styles['voucher-description-item'] } d-flex align-items-center justify-content-between mt-1`}
                            >
                                <p>
                                    <FormattedMessage id="wallet-voucher-details.date" />
                                </p>
                                <p className="bold">{moment(voucher.createdAt).format('DD MMMM yyyy, hh:mm')}</p>
                            </div>
                            {voucher?.discountCondition && (
                                <div
                                    className={`${ styles['voucher-description-item'] } d-flex align-items-center justify-content-between  mt-1`}
                                >
                                    <p>
                                        <FormattedMessage id="voucher-details.single.reduction-condition" />
                                    </p>
                                    <p className="bold" dangerouslySetInnerHTML={{ __html: voucher.discountCondition }} />
                                </div>
                            )}
                            {voucher?.minDiscountCalculatedAmount ? (
                                <div
                                    className={`${ styles['voucher-description-item'] } d-flex align-items-center justify-content-between mt-1`}
                                >
                                    <p>
                                        <FormattedMessage id="voucher-details.single.min-discount-calculated-amount" />
                                    </p>
                                    <p className="bold">{voucher.minDiscountCalculatedAmount}</p>
                                </div>
                            ) : null}
                            {voucher?.maxDiscountCalculatedAmount ? (
                                <div
                                    className={`${ styles['voucher-description-item'] } d-flex align-items-center justify-content-between mt-1`}
                                >
                                    <p>
                                        <FormattedMessage id="voucher-details.single.max-discount-calculated-amount" />
                                    </p>
                                    <p className="bold">{voucher.maxDiscountCalculatedAmount}</p>
                                </div>
                            ) : null}
                            {voucher.expireAt && (
                                <div
                                    className={`${ styles['voucher-description-item'] } d-flex align-items-center justify-content-between mt-1`}
                                >
                                    <p>
                                        <FormattedMessage id="wallet-voucher-details.DLV" />
                                    </p>
                                    <p className="bold">
                                        <FormattedMessage
                                            id="date.format-duration.d-h-m-s"
                                            values={{
                                                days:    expiredAt.days,
                                                hours:   expiredAt.hours,
                                                minutes: expiredAt.minutes,
                                                seconds: expiredAt.seconds,
                                            }}
                                        />
                                    </p>
                                </div>
                            )}
                        </div>
                        {canUseOnline && canUseOffline && (
                            <>
                                <div className={styles.profitOnline}>
                                    {!hasOnlineCode ? (
                                        <>
                                            <h6 className="mt-4 mb-3 text-center">
                                                <FormattedMessage id="wallet-voucher-details.online.profit-title"/>
                                            </h6>
                                            <button 
                                                className={`btn-lg-primary mb-0 ${ styles.voucherButton }`} 
                                                type="button"
                                                onClick={() => setShowProfitPopup(true)}
                                            >
                                                <FormattedMessage id="wallet-voucher-details.online.profit-button"/>
                                            </button>

                                            <h5 className="mt-4 mb-4 text-center">
                                                <FormattedMessage id="wallet-voucher-details.online.or"/>
                                            </h5>

                                            <h6 className="mb-3 text-center">
                                                <FormattedMessage id="wallet-voucher-details.online.get-code-title"/>
                                            </h6>
                                            <button 
                                                className={`mb-4 btn-lg-primary ${ styles.voucherButton }`} 
                                                type="button"
                                                onClick={() => !canUseOffline ? codeRef.current?.onGetOnlineCode() : setShowUseCodePopup(true)}
                                            >
                                                <FormattedMessage id="wallet-voucher-details.online.get-code-button"/>
                                            </button>
                                        </>
                                    ): (
                                        <>
                                            <div className={styles.onlineCode}>
                                                <h2>{discountCode}</h2>
                                                <div  onClick={copyCode}>
                                                    {codeCopied ? (
                                                        <p>
                                                            <FormattedMessage id="wallet.voucher-details.use-online.code-copied" />
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            <FormattedMessage id="wallet.voucher-details.use-online.code-copy" />
                                                        </p>
                                                    )}
                                                    <svg width="17" height="23" viewBox="0 0 17 23" fill="none">
                                                        <rect x="3.4375" y="0.25" width="13.3125" height="19.75" rx="2.75" stroke="#797979" stroke-width="0.5" stroke-dasharray="0.4 0.4"/>
                                                        <rect x="0.5" y="3.19922" width="12.8125" height="19.25" rx="2.5" stroke="#797979"/>
                                                    </svg>
                                                </div>
                                            </div>

                                            <h6 className="mt-5 mb-2 text-center">
                                                <FormattedMessage id="wallet-voucher-details.online.use-code-in"/>
                                            </h6>
                                            <a 
                                                target='_blank' 
                                                href={voucher.website}
                                                className="text-center"
                                            >
                                                {voucher.website}
                                            </a>
                                        </>
                                    )}
                                </div>
                                {!hasOnlineCode ? (
                                    <>
                                        <h6 className="mt-4 mb-3 text-center">
                                            <FormattedMessage id="wallet-voucher-details.online.footer-title"/>
                                        </h6>
                                        <button 
                                            className={`btn-lg-primary ${ styles.voucherButton }`} 
                                            type="button"
                                            disabled={isStoryLoading}
                                            onClick={goToStory}
                                        >
                                            {isStoryLoading 
                                                ? <Loader fill={LoaderInButtonFillColor} />
                                                :<FormattedMessage id="wallet-voucher-details.online.footer-button"/>}
                                        </button>
                                    
                                    </>
                                ): (
                                    <>
                                        <h6 className="mb-3 text-center">
                                            <FormattedMessage id="wallet-voucher-details.online.reclaim.footer-title"/>
                                        </h6>
                                        <button 
                                            className={`btn-lg-primary ${ styles.voucherButton }`} 
                                            type="button"
                                            onClick={() => setShowValidationPopup(true)}
                                        >
                                            <FormattedMessage id="wallet-voucher-details.online.reclaim.footer-button"/>
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                        {!canUseOnline && canUseOffline && (
                            <div className={styles.canUseOffline}>
                                <h6 className="mb-4 text-center w-100">
                                    <FormattedMessage 
                                        id="wallet-voucher-details.footer-title"
                                        values={{
                                            span: (msg) => <span>{msg}</span>,
                                        }}
                                    />
                                </h6>
                                <button
                                    className={`btn-lg-primary ${ styles.voucherButton }`} 
                                    type="button"
                                    onClick={() => setShowProfitPopup(true)}
                                >
                                    <FormattedMessage id="wallet-voucher-details.footer-button"/>
                                </button>
                            </div>
                        )}
                        {canUseOnline && !canUseOffline && (
                            <>
                                <div className={styles.profitOnline}>
                                    {!hasOnlineCode ? (
                                        <>
                                            <button 
                                                className={`btn-lg-primary ${ styles.voucherButton }`} 
                                                type="button"
                                                onClick={() => !canUseOffline ? codeRef.current?.onGetOnlineCode() : setShowUseCodePopup(true)}
                                                style={{
                                                    padding: '1.8rem 1rem',
                                                    margin: '1.5rem 0',
                                                    fontSize: '1.3rem'
                                                }}
                                            >
                                                <FormattedMessage id="wallet-voucher-details.online.get-code-button"/>
                                            </button>
                                        </>
                                    ): (
                                        <>
                                            <div className={styles.onlineCode}>
                                                <h2>{discountCode}</h2>
                                                <div  onClick={copyCode}>
                                                    {codeCopied ? (
                                                        <p>
                                                            <FormattedMessage id="wallet.voucher-details.use-online.code-copied" />
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            <FormattedMessage id="wallet.voucher-details.use-online.code-copy" />
                                                        </p>
                                                    )}
                                                    <svg width="17" height="23" viewBox="0 0 17 23" fill="none">
                                                        <rect x="3.4375" y="0.25" width="13.3125" height="19.75" rx="2.75" stroke="#797979" stroke-width="0.5" stroke-dasharray="0.4 0.4"/>
                                                        <rect x="0.5" y="3.19922" width="12.8125" height="19.25" rx="2.5" stroke="#797979"/>
                                                    </svg>
                                                </div>
                                            </div>

                                            <h6 className="mt-5 mb-2 text-center">
                                                <FormattedMessage id="wallet-voucher-details.online.use-code-in"/>
                                            </h6>
                                            <a 
                                                target='_blank' 
                                                href={voucher.website}
                                                className="text-center"
                                            >
                                                {voucher.website}
                                            </a>
                                        </>
                                    )}
                                </div>
                                {!hasOnlineCode ? (
                                    <>
                                        <h6 className="mt-4 mb-3 text-center">
                                            <FormattedMessage id="wallet-voucher-details.online.footer-title"/>
                                        </h6>
                                        <button 
                                            className={`btn-lg-primary ${ styles.voucherButton }`} 
                                            type="button"
                                            disabled={isStoryLoading}
                                            onClick={goToStory}
                                        >
                                            {isStoryLoading 
                                                ? <Loader fill={LoaderInButtonFillColor} />
                                                :<FormattedMessage id="wallet-voucher-details.online.footer-button"/>}
                                        </button>
                                    
                                    </>
                                ): (
                                    <>
                                        <h6 className="mb-3 text-center">
                                            <FormattedMessage id="wallet-voucher-details.online.reclaim.footer-title"/>
                                        </h6>
                                        <button 
                                            className={`btn-lg-primary ${ styles.voucherButton }`} 
                                            type="button"
                                            onClick={() => setShowValidationPopup(true)}
                                        >
                                            <FormattedMessage id="wallet-voucher-details.online.reclaim.footer-button"/>
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    isLoading && <Loader />
                )}

                {isEmpty(voucher) && !isLoading && (
                    <NoResult
                        message={intl.formatMessage({
                            id: 'wallet.voucher-details.not-found',
                        })}
                    />
                )}
            </PageTransitionWrapper>
            
            {voucher?.orderId && <WalletVoucherDetailsOnlineValidation
                orderId={voucher.orderId}
                company={voucher?.companies?.[0]}
                shouldShow={showValidationPopup} 
                onClose={() => setShowValidationPopup(false)}
                onValidationSuccess={() => {
                    setVoucher(null);
                    setShouldRerender(!shouldRerender);
                    setShowValidationPopup(false);
                }}
            />}
            {voucher?.id && <WalletVoucherDetailsUseCode
                ref={codeRef}
                offerId={voucher?.id}
                shouldShow={showUseCodePopup} 
                onClose={() => {
                    setVoucher(null);
                    setShouldRerender(!shouldRerender);
                    setShowUseCodePopup(false);
                }}
            />}
            <WalletVoucherDetailsProfit 
                shouldShow={showProfitPopup} 
                onClose={() => setShowProfitPopup(false)}
            />
        </>
    );
}

export default WalletVoucherDetails;
