/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line
// @ts-ignore
import kfcLogo from 'assets/Images/kfc.png';
// eslint-disable-next-line
// @ts-ignore
import validationSuccess from 'assets/Images/validation-success.png';
// eslint-disable-next-line
// @ts-ignore
import validationFailed from 'assets/Images/validation-failed.png';
// eslint-disable-next-line
// @ts-ignore
import validationPending from 'assets/Images/validation-pending.png';

import PrimaryButton from 'components/form/button/PrimaryButton';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import './walletVoucherDetailsOnlineValidation.scss';
import { PremiumBackButton } from 'components/premium-screens/back-button/PremiumBackButton';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import useLocale from 'hooks/locale/useLocale';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import useWalletCouponOnline from 'hooks/wallet/useWalletCouponOnline';
import useToast from 'components/widgets/toast/useToast';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import Img from 'components/img/Img';
import { generateImageUrl } from 'services/url';

interface WalletVoucherDetailsOnlineValidationProps {
    company: { id: number; name: string; logo: string };
    orderId: string;
    shouldShow: boolean;
    onClose: () => void;
    onValidationSuccess?: () => void;
}

const WalletVoucherDetailsOnlineValidation = ({
    company,
    orderId,
    shouldShow,
    onClose,
    onValidationSuccess,
}: WalletVoucherDetailsOnlineValidationProps) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const lang = useLocale();
    const { triggerToast } = useToast();

    const [document, setDocument] =  useState<File>(null);
    const fileInputRef = useRef(null);
    const {
        requestValidation,
        isLoading: isRequestValidationLoading,
        refetchValidationStatus,
        validationStatus: {
            isSubmitted,
            isValidating,
            isApproved,

            // website,
        },
    } = useWalletCouponOnline();

    useEffect(() => {
        if (!shouldShow) {
            return;
        }

        refetchValidationStatus(orderId);
    }, [shouldShow]);

    const handleFileSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (files.length > 0) {
            const [file] = files;

            setDocument(file);
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isRequestValidationLoading || isSubmitted) {
            return;
        }

        if (!document) {
            triggerToast(intl.formatMessage({ id: 'points-history.validation-form.missing-document' }), 'warn');

            return;
        }

        const response = await requestValidation({
            document,
            id: orderId,
        });

        if (response && response?.success === true) {
            triggerToast(intl.formatMessage({ id: 'points-history.validation-form.success-response' }), 'success');
            onValidationSuccess?.();
            refetchValidationStatus?.(orderId);
        } else {
            triggerToast(intl.formatMessage({ id: 'points-history.validation-form.error-response' }), 'error');
        }
    };
    const goToHistory = () => navigate(All_PATHS_NAMESPACES[lang].points_history.path);

    return (
        <DraggablePopup
            className="wallet-use-code-wrapper"
            show={shouldShow}
            onClose={onClose}
            wrapperStyle={{ minHeight: '100vh' }}
            shouldShowHandle={false}
            isDraggable={false}
        >
            <form className="wallet-use-code" onSubmit={onSubmit}>
                <PremiumBackButton onClick={onClose}/>

                <h4><FormattedMessage id="wallet-voucher-details.online.reclaim-validation.paragraph"/></h4>

                <Img src={generateImageUrl(company?.logo)} alt="brand logo" />
                <h3>{company?.name}</h3>

                <h2><FormattedMessage id="wallet-voucher-details.online.reclaim-validation.title"/></h2>
                <p><FormattedMessage id="wallet-voucher-details.online.reclaim-validation.subtitle"/></p>
                <div onClick={() => fileInputRef.current?.click()} className="input-wrapper file-input-wrapper">
                    <p>{document?.name ?? intl.formatMessage({
                        id: 'wallet-voucher-details.online.reclaim-validation.input2-text',
                    })}</p>
                    <input
                        ref={fileInputRef}
                        className="wallet-file-input"
                        name="document"
                        type="file"
                        onChange={handleFileSelectChange}
                    />
                </div>
                <PrimaryButton disabled={isRequestValidationLoading || isSubmitted} type="submit" style={{ maxWidth: '80%' }}>
                    {isRequestValidationLoading ? <Loader fill={LoaderInButtonFillColor} /> : (
                        <FormattedMessage id="wallet-voucher-details.online.reclaim-validation.confirm-button" />
                    )}
                </PrimaryButton>

                <h2><FormattedMessage id="wallet-voucher-details.online.reclaim-validation.validation-title"/></h2>

                <div className="status-items">
                    <div className="status-item-line" />
                    <StatusItem
                        icon={isSubmitted ? validationSuccess : validationPending}
                        reverseFlex
                        title={intl.formatMessage({ id: 'wallet-voucher-details.online.reclaim-validation.validation-step1' })}
                    />
                    <StatusItem
                        icon={(
                            isSubmitted && isValidating
                                ? validationSuccess
                                : validationPending
                        )}
                        title={intl.formatMessage({ id: 'wallet-voucher-details.online.reclaim-validation.validation-step2' })}
                    />
                    <StatusItem
                        icon={(
                            isApproved === 'ACCEPTED'
                                ? validationSuccess
                                : isApproved === 'REFUSED' ? validationFailed : validationPending
                        )}
                        reverseFlex
                        title={(
                            isApproved === 'ACCEPTED'
                                ? intl.formatMessage({ id: 'wallet-voucher-details.online.reclaim-validation.validation-step3.accepted' })
                                : isApproved === 'REFUSED'
                                    ? intl.formatMessage({ id: 'wallet-voucher-details.online.reclaim-validation.validation-step3.refused' })
                                    : intl.formatMessage({ id: 'wallet-voucher-details.online.reclaim-validation.validation-step3.pending' })
                        )}
                    />
                </div>

                <h4><FormattedMessage id="wallet-voucher-details.online.reclaim-validation.footer-text"/></h4>
                <PrimaryButton onClick={goToHistory}>
                    <FormattedMessage id="wallet-voucher-details.online.reclaim-validation.history-button" />
                </PrimaryButton>
            </form>
        </DraggablePopup>
    );
};

WalletVoucherDetailsOnlineValidation.defaultProps = {
    onValidationSuccess: undefined,
};

const StatusItem = ({
    icon, title, reverseFlex, disabled,
}: { icon: string; title: string; disabled?: boolean; reverseFlex?: boolean }) => (
    <div className={classNames('status-item', {
        '--reverseFlex': reverseFlex,
        '--disabled':    disabled,
    })}>
        <img src={icon} alt="validation status icon"/>
        <p>{title}</p>
    </div>
);

StatusItem.defaultProps = {
    reverseFlex: false,
    disabled:    false,
};

export default WalletVoucherDetailsOnlineValidation;
